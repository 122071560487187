import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="popup"
export default class extends Controller {
  initialize() {
    super.initialize();
    const options = {};
    this.popup = new bootstrap.Modal(this.element, options);
  }

  connect() {
    // 저장된 체크박스 상태 가져오기
    const noMorePopup = localStorage.getItem('noMorePopup');
    const noMorePopupDate = localStorage.getItem('noMorePopupDate');

    // 체크박스 상태에 따라 모달 열기
    if (noMorePopup === 'true' && noMorePopupDate === new Date().toDateString()) {
      // 오늘 하루 열지 않기 체크박스를 체크한 경우
      return;
    } else {
      // 오늘 하루 열지 않기 체크박스를 체크하지 않은 경우
      this.popup.show();
    }

    // 모달이 닫힐 때 실행되는 함수
    this.element.addEventListener('hidden.bs.modal', (_event) => {
      // 체크박스 요소 가져오기
      const noMorePopupCheckbox = this.element.querySelector('#noMorePopup');

      // 체크박스 상태 저장
      if (noMorePopupCheckbox.checked) {
        localStorage.setItem('noMorePopup', 'true');
        localStorage.setItem('noMorePopupDate', new Date().toDateString());
      } else {
        localStorage.setItem('noMorePopup', 'false');
        localStorage.removeItem('noMorePopupDate');
      }
    });
  }

  handleCloseModal(event) {
    this.popup.hide();
  }
}
