import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

// Connects to data-controller="incremental-search"
export default class extends Controller {
  static targets = ["searchField"];

  connect() {
    this.search = debounce(this.search.bind(this), 300);
  }

  search() {
    const query = this.searchFieldTarget.value;
    // console.log("Query:", query); // 입력값을 콘솔에 출력
    const baseUrl = this.data.get("url");

    if (!baseUrl) {
      console.error("Base URL is not defined");
      return;
    }

    const url = new URL(baseUrl, window.location.origin); // 절대 경로로 변환

    url.searchParams.set("q[license_no_or_full_name_cont]", query);

    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((html) => {
        // console.log("Fetched HTML:", html); // 응답으로 받은 HTML을 콘솔에 출력
        const resultsElement = document.getElementById("results");
        if (resultsElement) {
          resultsElement.innerHTML = html;
          // console.log("Results element updated:", resultsElement.innerHTML); // 업데이트된 결과를 콘솔에 출력
        } else {
          console.error("Results element not found");
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
      });
  }
}
