import tinymce from "tinymce/tinymce";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    this.defaults = {
      base_url: "/assets/tinymce",
      menubar: "file edit view insert format tools table",
      plugins: "fullscreen lists link image table code help wordcount",
      toolbar:
        "undo redo | styleselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image table | code | fullscreen",
      toolbar_sticky: true,
      images_upload_url: "/image_uploader/image",
      file_picker_types: "file image media",
      suffix: ".min",
      relative_urls: false,
      // skin: window.matchMedia("(prefers-color-scheme: dark)").matches
      //   ? "oxide-dark"
      //   : "oxide",
      // content_css: window.matchMedia("(prefers-color-scheme: dark)").matches
      //   ? "dark"
      //   : "default",
    };
  }

  connect() {
    // Initialize the app
    let config = Object.assign({ target: this.inputTarget }, this.defaults);
    tinymce.init(config);
  }

  disconnect() {
    tinymce.remove();
  }
}
