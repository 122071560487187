import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="open-type"
export default class extends Controller {
  static targets = ["openType", "preregFee", "preregFeeOnline"];
  static values = { opening: String };

  connect() {
    console.log("Open Type Controller connected...");
    this.changeOpenType(this.openingValue);
  }

  handleChangeOpenType(event) {
    const openType = event.target.value;
    this.changeOpenType(openType);
  }

  changeOpenType(open_type) {
    if (open_type === "0") {
      this.preregFeeTarget.classList.remove("hidden");
      this.preregFeeOnlineTarget.classList.add("hidden");
    } else if (open_type === "1") {
      this.preregFeeTarget.classList.add("hidden");
      this.preregFeeOnlineTarget.classList.remove("hidden");
    } else {
      this.preregFeeTarget.classList.remove("hidden");
      this.preregFeeOnlineTarget.classList.remove("hidden");
    }
  }
}
