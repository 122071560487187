import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ['memberTypeCd', 'positionTypeCd', 'confirmed', 'fullName', 'licenseNo']
  static values = {
    conferenceId: Number,
  }
  connect() { }

  async incrementalSearch(){
    let url = `/admin/conferences/${this.conferenceIdValue}/preregistrations?member_type_cd=${this.memberTypeCdTarget.value}&position_type_cd=${this.positionTypeCdTarget.value}&confirmed=${this.confirmedTarget.value}&full_name=${this.fullNameTarget.value}&license_no=${this.licenseNoTarget.value}`
    const request = new FetchRequest('get', url, { responseKind: "turbo-stream" })
    const response = await request.perform()
    if (response.ok) {
      const body = await response.text
      // Do whatever do you want with the response body
      // You also are able to call `response.html` or `response.json`, be aware that if you call `response.json` and the response contentType isn't `application/json` there will be raised an error.
      if(this.memberTypeCdTarget.value == "" &&
      this.positionTypeCdTarget.value == "" &&
      this.confirmedTarget.value == "" &&
      this.fullNameTarget.value == "" &&
      this.licenseNoTarget.value == "") {
        window.history.pushState("", "", url.split('?')[0]);
      } else {
        window.history.pushState("", "", url);
      }
    }
  }
}
