// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import "./src/jquery";
import "trix";
import "@rails/actiontext";
import "@fortawesome/fontawesome-free/js/all";
import * as bootstrap from "bootstrap";
window.bootstrap = bootstrap;

// 이벤트 위임을 사용하여 동적으로 추가된 요소에도 이벤트가 적용되도록 수정
document.addEventListener("click", (event) => {
  // 클릭된 요소가 .cell-text 클래스인지 확인
  if (event.target.matches(".cell-text")) {
    const cell = event.target.closest('[data-behavior="editable-cell"]');
    if (cell) {
      const textElement = cell.querySelector(".cell-text");
      const inputElement = cell.querySelector('[data-behavior="inline-edit"]');
      const formElement = cell.querySelector(".inline-form");

      textElement.classList.add("d-none");
      inputElement.classList.remove("d-none");
      inputElement.focus();
    }
  }
});

document.addEventListener(
  "blur",
  (event) => {
    if (event.target.matches('[data-behavior="inline-edit"]')) {
      const inputElement = event.target;
      const cell = inputElement.closest('[data-behavior="editable-cell"]');
      const textElement = cell.querySelector(".cell-text");
      const formElement = cell.querySelector(".inline-form");

      // 필요시 폼 제출
      // formElement.requestSubmit();

      inputElement.classList.add("d-none");
      textElement.classList.remove("d-none");
    }
  },
  true
); // Capture 단계에서 이벤트를 포착

document.addEventListener("keydown", (event) => {
  if (
    event.target.matches('[data-behavior="inline-edit"]') &&
    event.key === "Enter"
  ) {
    const inputElement = event.target;
    const cell = inputElement.closest('[data-behavior="editable-cell"]');
    const textElement = cell.querySelector(".cell-text");
    const formElement = cell.querySelector(".inline-form");

    formElement.requestSubmit();
    inputElement.classList.add("d-none");
    textElement.classList.remove("d-none");
  }
});
