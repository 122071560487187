import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

// Connects to data-controller="confirm"
export default class extends Controller {
  static targets = ["preregistration", "checkAllChecked"];
  static values = {
    conferenceId: Number,
  };
  connect() {}

  checkAll() {
    // e.preventDefault()
    const allPregistrationTargets = this.preregistrationTargets;
    // console.log(this.userAllCheckedTarget.checked)
    allPregistrationTargets.forEach((preregistration) => {
      preregistration.checked = !preregistration.checked;
    });
  }

  async confirmAll(e) {
    e.preventDefault();

    const conferenceId = this.conferenceIdValue;
    const preregistrationChecks = this.preregistrationTargets.filter(
      (preregistration) => preregistration.checked
    );
    const ids = preregistrationChecks
      .map((preregistration) => preregistration.value)
      .join(",");
    const url = `/admin/conferences/${conferenceId}/confirm_all_deposits?ids=${ids}`;
    if (preregistrationChecks.length === 0) {
      alert("원하는 항목을 선택한 후 계속하세요.");
    } else {
      if (confirm(`입금확인 일괄작성을 계속하시겠습니까?`) === true) {
        // console.log(url);
        const request = new FetchRequest("post", url, {
          responseKind: "turbo-stream",
        });
        const response = await request.perform();
        if (response.ok) {
          // $('#spinner-for-submit').fadeOut('slow');
        }
      }
    }
  }
}
