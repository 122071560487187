import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="depositor"
export default class extends Controller {
  static targets = ["fullName", "depositor"]
  connect() {
  }

  handleKeyup(e) {
    this.depositorTarget.value = this.fullNameTarget.value
  }
}
